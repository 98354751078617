@media screen and (min-width: 100px) and (max-width: 470px){
  .change {
    background-image: url("./classe2.jpeg");
    width: 100%;
    height:130px;
          
    }
    .h2 {
      font-size: 0.9rem;
    }
    .h7 {
      font-size: 0.5rem;
    }
    .pied-de-page {font-size: .45rem;}
}
@media screen and (min-width: 471px) and (max-width: 767px){
  .change {
    background-image: url("./classe3.jpeg");
    width: 100%;
    height:150px;
   
          
    }
     .pied-de-page {font-size: .625rem;}
}

@media screen and (min-width: 768px) {
  .change {
    background-image: url("./classe1.jpeg");
    width: 100%;
    height:200px;
       
    }
    .pied-de-page {font-size: .725rem;}
    
}





.btn-primary{
    border: none;
    border-radius: 1px;
    background: rgb(230,110,171);
    background: linear-gradient(90deg, rgba(230,110,171,0.9808298319327731) 20%, rgba(198,98,226,1) 80%);
  }
  
  a{
    text-decoration: none;
  }
  

  p.card-text {
    font-size: 0.9rem;
  }

.soustitre{
  display:block;
  font: 0.6em "Fira Sans", sans-serif;
  max-width: 150px;
}

.titre{
  font: small-caps bold 24px/1 sans-serif;
}

.dic,.otscis,.msost,.ip{
   color: white;
  margin: 5% 0;
  height: 75px;
  border: 1px solid black;
  width:175px;
}

.dic{background-color: rgb(161, 163, 174);
  border-radius: 1%;
  box-shadow: 6px 6px 25px rgba(0, 0, 0, 0.5);
}

.dic:hover {
  background-color: rgb(209, 210, 214);
}

.otscis{background-color: rgb(147, 187, 161);
  border-radius: 1%;
  box-shadow: 6px 6px 25px rgba(0, 0, 0, 0.5);
}

.otscis:hover {
  background-color: rgb(190, 221, 201);
}

.msost{ 
  background: rgb(100, 174, 209);
  border-radius: 1%;
  box-shadow: 6px 6px 25px rgba(0, 0, 0, 0.5);
}
.msost:hover {
  background-color: rgb(223, 241, 243);
}

.ip{ 
  background: rgb(193, 186, 145);
  border-radius: 1%;
  box-shadow: 6px 6px 25px rgba(0, 0, 0, 0.5);
}

.ip:hover {
  background-color: rgb(234, 227, 185);
}

.niveau {
  color: rgb(45, 206, 238);
}


.cinq:hover figcaption,.cinq:hover{
 color : #198754 ; 
 cursor: pointer;
}

 
.quatre:hover figcaption,.quatre:hover{
  color : #d63384;
  cursor: pointer;
  }
 
.trois:hover figcaption,.trois:hover{
  color : #fd7e14 ; 
  cursor: pointer;
 }

figcaption {
  font-size: 75%;
  color: rgb(45, 206, 238);
  font: italic smaller sans-serif;
  text-align: center;
}

 
.iconCardType svg {
  max-width: 60px;
  width: 30%;
  height: auto;
}


p.card-text {
  font-size: .9rem;
}
.iconCardType {
  text-align: center;
}

.iconCardText {
  color: #000;
  font-size: .8rem;
  margin-top: .75rem !important;
  text-align: center;
  
}

.activity {
  height: 135px;
}

.morphext {
    padding-top: 40px;
   }
    
.morphext > span {
  display: none;
}


.morphext .animated {
  display: inline-block;
  font-weight: bold;
 }

.h7 {
  font-size: 0.7rem;
}


.code-title{
  margin-bottom:32px;
  font-size:2rem;
  text-align: center;}

  .codiv{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1.5rem;
  box-shadow: 6px 6px 25px rgba(0, 0, 0, 0.5);
}

input.input-code {
	box-sizing: content-box;
	min-height: 50px;
	padding: 0 1px 1px .5ch;
	color: #344563;
	font-size: 1.875rem;
	font-family: "Roboto Mono",Arial,sans-serif;
	letter-spacing: .5ch;
	text-transform: uppercase;
	background: repeating-linear-gradient(90deg,#c1c7d0 0,#c1c7d0 1ch,transparent 0,transparent 1.5ch) 0 96%/98% 2px no-repeat;
	background-origin: content-box;
	border: solid 2px #dfe1e6;
	border-radius: 3px;
}
.codentry input {
	width: 14.6ch;
}

.codentry{
    display: inline-block;
    margin-bottom: 16px;
    text-align: left
  }

.bs-btn-
 {padding-block:.5rem.25rem;  
  font-size: .75rem;}


.info {
    padding: .5rem;
    background-color: #46A7F5;
    color: white;
    margin-top: 15px;
    font-size: 1rem;
    text-align :center;
    border-radius: 3px;
    box-shadow: 6px 6px 25px rgba(0, 0, 0, 0.5);
  }

  .modif {
    background-color:#212529;
    color:white;
    border-radius: 3px;
    padding: 0px 10px 0px 10px;
    margin-left: 10px;
   }

   .modif :hover {
    background-color:whitesmoke;
    color: black;
   }

   .information{
    color: red;
   }

 