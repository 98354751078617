body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.cardcontent {
  min-height: 100px;
}
.titlecard {
  padding-top: 10px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  visibility: visible;
  word-break: break-word;
  width: 90%;
}
.classescard {
  position: absolute;
  top: 0.1rem;
  right: 0.1rem;
}
.classescard span {
  float: right;
  border-radius: 50%!important;
  width: 15px;
  margin: .07rem .07rem;
  font-size: 10px;
  height: 15px;
  text-align: center;
  padding: 1px;
  color: #fff;
 }
 .card-text {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  visibility: visible;
  word-break: break-word;
  margin-bottom: 0.25rem;
  line-height: 1rem;
  color: var(--text-body);

 }
 .classescard span.classe6 {
  background-color: #6f42c1;
 }
 .classescard span.classe5 {
  background-color: #198754;
 }
 .classescard span.classe4 {
  background-color: #d63384;
 }
 .classescard span.classe3 {
  background-color: #fd7e14;
 }

 .navbar-brand .subtitle {
  text-transform: uppercase;
  font-size: .625rem;
  letter-spacing: .125rem;
 }


.card:hover.card-highlight--hp {
  -webkit-box-shadow: unset;
  box-shadow: unset;
  -webkit-transform: unset;
  -ms-transform: unset;
  transform: unset
}

.card.card-highlight--hp {
  cursor: pointer;
  overflow: hidden;
  border-radius: .5rem;
  -webkit-transition: -webkit-transform .3s cubic-bezier(.16,1,.99,.99);
  transition: -webkit-transform .3s cubic-bezier(.16,1,.99,.99);
  -o-transition: transform cubic-bezier(.16,1,.99,.99) .3s;
  transition: transform .3s cubic-bezier(.16,1,.99,.99);
  transition: transform .3s cubic-bezier(.16,1,.99,.99),-webkit-transform .3s cubic-bezier(.16,1,.99,.99);
  max-width: 71.25rem;
  position: relative;
  -webkit-box-shadow: unset;
  box-shadow: unset
}

.card.card-highlight--hp picture {
  width: 100%;
  height: 355px;
  display: block;
  -webkit-filter: brightness(.8);
  filter: brightness(.8)
}

@media(min-width: 768px) {
  .card.card-highlight--hp picture {
      width:1370px;
      height: 460px
  }
}

.card .card-highlight--hp__imagearea {
  max-height: 355px
}

@media(min-width: 768px) {
  .card .card-highlight--hp__imagearea {
      max-height:460px
  }
}

.card .card-highlight--hp__title {
  position: absolute;
  bottom: 72px;
  left: 42px;
  width: 100%;
  z-index: 2
}

.card .card-highlight--hp__title h3,.card .card-highlight--hp__title p {
  color: #fff;
  letter-spacing: 1px;
  line-height: 1.2;
  max-width: 85%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  visibility: visible;
  word-break: break-word
}

.card .card-highlight--hp__title h3 {
  font-family: Poppins-SemiBold;
  display: inline-block;
  font-size: clamp(18px,4vw,34px);
  margin-bottom: 20px
}

.card .card-highlight--hp__title p {
  display: none
}

@media(min-width: 768px) {
  .card .card-highlight--hp__title p {
      display:-webkit-box;
      font-family: Poppins-Light;
      font-size: clamp(18px,4vw,22px);
      max-width: 65%
  }
}

@media(min-width: 1200px) {
  .wrapper--with-menu .card.card-highlight--hp picture {
      max-width:clamp(925px,75vw,1115px)
  }
}

.card--mea {
  width: 11.875rem;
  height: 16.25rem
}

.card--mea .card__imagearea {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover
}

.card--mea .card__imagearea:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  background: -webkit-gradient(linear,left top,left bottom,from(rgba(0,0,0,0)),to(rgba(0,0,0,.7)));
  background: -o-linear-gradient(top,rgba(0,0,0,0) 0,rgba(0,0,0,.7) 100%);
  background: linear-gradient(180deg,rgba(0,0,0,0),rgba(0,0,0,.7))
}

.card--mea .card__info {
  position: absolute;
  bottom: 1.25rem;
  margin: 0;
  padding: 0 .875rem 0 1.125rem;
  background: rgba(0,0,0,0)
}

.card--mea .card__info:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: .375rem;
  height: 100%;
  background: var(--bg-theme-gradient);
  border-radius: 0 .1875rem .1875rem 0
}

.card--mea .card__info .card__subtitle span,.card--mea .card__info .card__title span {
  display: inline;
  line-height: 1.6;
  background: var(--white);
  -ms-box-decoration-break: clone;
  -o-box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  -webkit-box-shadow: .25rem 0 0 0 var(--white),-.25rem 0 0 0 var(--white);
  box-shadow: .25rem 0 0 0 var(--white),-.25rem 0 0 0 var(--white)
}

.card--mea .card__info .card__title {
  max-width: unset;
  margin: 0;
  font-size: .875rem
}

.card--mea .card__info .card__subtitle {
  display: none;
  margin: 0;
  font-size: .875rem
}

@media(min-width: 1200px) {
  .card--mea {
      width:15.9375rem;
      height: 21.875rem
  }

  .card--mea .card__info .card__title {
      margin: 0 0 .25rem;
      font-size: 1rem
  }

  .card--mea .card__info .card__subtitle {
      overflow: unset;
      display: unset
  }
}

.card.card-highlight {
  position: relative;
  overflow: inherit;
  width: 13.125rem;
  height: 17.5rem;
  border-radius: .625rem
}

@media(min-width: 1200px) {
  .card.card-highlight {
      width:17.1875rem;
      height: 23.75rem
  }
}

.card-highlight__label {
  position: absolute;
  top: -.875rem;
  right: 37%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: .25rem .375rem;
  font-family: Poppins-SemiBold;
  font-size: .6875rem;
  line-height: 1.3125rem;
  color: var(--white);
  letter-spacing: .03125rem;
  border-radius: .25rem
}

@media(min-width: 1200px) {
  .card-highlight__label {
      padding:.375rem .5rem;
      font-size: .875rem
  }
}

.card-highlight__image {
  width: 100%;
  height: 100%;
  border-radius: .625rem
}

.card-highlight__imagearea {
  width: 100%
}

.card-highlight__label[data-bg=primaire] {
  background: #ff878c
}

.card-highlight__label[data-bg=college] {
  background: #6370fa
}

.card-highlight__label[data-bg=lycee] {
  background: #ad70fc
}

.card-highlight__label[data-bg=etudiant] {
  background: #fc871e
}

.card-link {
  height: 100%;
  min-height: 55px;
  max-height: 120px;
  border: 1px solid #c8cacb;
  border-radius: 8px;
  -webkit-box-shadow: 0 2px 10px rgba(0,0,0,.1);
  box-shadow: 0 2px 10px rgba(0,0,0,.1);
  padding: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: center;
  background: var(--white);
  color:var(--dark-grey);
  font-size: .925rem;
}

.card-link__legend {
  color: var(--color-text-body);
  margin-bottom: 4px
}

@media(min-width: 1200px) {
  .card-link.chapter:hover {
      border:2px solid var(--bg-theme);
      cursor: pointer
  }
}

@media(min-width: 1200px) {
  .card-link.chapter:focus-visible {
      outline:2px solid var(--chapter-focus)
  }
}

.card-preview {
  position: absolute;
  left: -624.9375rem;
  width: 15.9375rem;
  height: 15.9375rem;
  opacity: 0;
  border-radius: .625rem;
  -webkit-box-shadow: 0 0 .625rem rgba(0,0,0,.1);
  box-shadow: 0 0 .625rem rgba(0,0,0,.1)
}

.card-preview--active {
  -webkit-transition: opacity .2s cubic-bezier(.32,.63,.66,.8) .1s;
  -o-transition: opacity .2s cubic-bezier(.32,.63,.66,.8) .1s;
  transition: opacity .2s cubic-bezier(.32,.63,.66,.8) .1s;
  z-index: 111;
  margin-top: -.3125rem;
  opacity: 1
}

@media(min-width: 1200px) {
  .card-preview .card[data-format="horizontal-s-fluid desktop-square"],.card-preview .card[data-format=square] {
      width:22.1875rem;
      height: 22.1875rem
  }

  .card-preview .card[data-format="horizontal-s-fluid desktop-square"].card--media .card__imagearea,.card-preview .card[data-format=square].card--media .card__imagearea {
      height: 12.5rem
  }

  .card-preview .card[data-format="horizontal-s-fluid desktop-square"]:hover,.card-preview .card[data-format=square]:hover {
      -webkit-transform: unset;
      -ms-transform: unset;
      transform: unset;
      -webkit-box-shadow: 0 0 .625rem rgba(0,0,0,.1);
      box-shadow: 0 0 .625rem rgba(0,0,0,.1)
  }
}

.card-preview__card {
  width: 22.1875rem
}

.card-preview__card .favorite-svg {
  display: none
}

.card-preview__player {
  z-index: 0;
  position: absolute!important;
  top: 0;
  left: 0;
  height: 12.5rem;
  aspect-ratio: 16/9;
  background-color: #0b0b0b;
  opacity: 0
}

.card-preview__player--active {
  z-index: 1;
  opacity: 1
}

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: all .1s ease-in-out;
  -o-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out
}

@supports(width: clamp(1px,1vw,2px)) {
  .card:hover {
      -webkit-transform:scale(1.03);
      -ms-transform: scale(1.03);
      transform: scale(1.03);
      -webkit-transition: all .1s ease-in-out;
      -o-transition: all .1s ease-in-out;
      transition: all .1s ease-in-out
  }

  .card:hover__clickarea {
      -webkit-box-shadow: 0 0 20px rgba(0,0,0,.2);
      box-shadow: 0 0 20px rgba(0,0,0,.2)
  }
}

.card__clickarea {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 0 0 .625rem rgba(0,0,0,.1);
  box-shadow: 0 0 .625rem rgba(0,0,0,.1);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: -webkit-transform .3s cubic-bezier(.16,1,.99,.99);
  transition: -webkit-transform .3s cubic-bezier(.16,1,.99,.99);
  -o-transition: transform cubic-bezier(.16,1,.99,.99) .3s;
  transition: transform .3s cubic-bezier(.16,1,.99,.99);
  transition: transform .3s cubic-bezier(.16,1,.99,.99),-webkit-transform .3s cubic-bezier(.16,1,.99,.99);
  border-radius: .5rem
}

.card__clickarea:focus {
  outline: var(--focus) solid .125rem
}

@supports not (width: clamp(1px,1vw,2px)) {
  .card__clickarea {
      overflow:unset
  }
}

.card__imagearea {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: var(--bg-placeholder)
}

.card__imagearea--restricted {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%)
}

.card__image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover
}

@supports not (width: clamp(1px,1vw,2px)) {
  .card__image {
      border-radius:.5rem .5rem 0 0
  }
}

.card .default-thumbnail {
  width: auto;
  height: auto
}

.card__label {
  position: absolute;
  z-index: 2;
  left: .5rem
}

.card__label {
  top: .4rem;
  padding: .3rem .5rem;
  font-size: .35rem;
  background: var(--bg-light);
  -webkit-box-shadow: 0 0 3px rgba(0,0,0,.2);
  box-shadow: 0 0 3px rgba(0,0,0,.2);
  border-radius: 3px
}

@media(min-width: 1200px) {
  .card__label {
      font-size:.5rem
  }
}

.card__icon {
  bottom: .5625rem;
  width: 1.5625rem;
  -webkit-filter: drop-shadow(0 0 3px rgba(0,0,0,.2));
  filter: drop-shadow(0 0 3px rgba(0,0,0,.2))
}

@media(min-width: 1200px) {
  .card__icon {
      bottom:.8125rem;
      width: auto
  }
}

.card__play-btn {
  position: absolute;
  padding: .125rem .375rem;
  font-size: .75rem;
  background: var(--bg-light);
  -webkit-box-shadow: 0 0 3px rgba(0,0,0,.2);
  box-shadow: 0 0 3px rgba(0,0,0,.2)
}

@media(min-width: 1200px) {
  .card__play-btn {
      padding:.25rem .75rem;
      font-size: .875rem
  }
}

.card__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  color: var(--bg-theme);
  background: var(--color-text-small);
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none
}

.card__progress-bar::-moz-progress-bar {
  background: linear-gradient(90deg,var(--bg-theme) 97.5%,var(--white) 0)
}

.card__progress-bar::-webkit-progress-value {
  background: -webkit-gradient(linear,left top,right top,color-stop(97.5%,var(--bg-theme)),color-stop(2.5%,var(--white)));
  background: linear-gradient(90deg,var(--bg-theme) 97.5%,var(--white) 0)
}

.card__progress-bar::-webkit-progress-bar {
  background: var(--color-text-small)
}

.card__viewed {
  position: absolute;
  top: .5rem;
  right: .5rem;
  display: none;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 1.25rem;
  padding: .25rem;
  color: var(--white);
  background: rgba(0,0,0,.3);
  border: .0625rem solid var(--white);
  border-radius: .25rem
}

.card__viewed img {
  width: .75rem
}

.card__viewed span {
  margin-left: .25rem;
  font-size: .75rem
}

.card__info {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: 100%;
  padding: .5rem 2.5rem .5rem .5rem;
  background-color: var(--bg-light)
}

.card__textarea {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%
}

.card__title {
  max-width: 85%;
  margin-bottom: .375rem;
  font-family: Poppins-SemiBold;
  font-size: .75rem;
  line-height: .9375rem;
  color: var(--text-titles);
  letter-spacing: .03125rem
}

.card__title em {
  background-color: var(--color-text-highlight)
}

@media(min-width: 1200px) {
  .card__title {
      font-size:.88rem;
      line-height: 1.125rem
  }
}

.card__description {
  color: var(--text-caption)
}

.card__subtitle {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  visibility: visible;
  word-break: break-word;
  margin-bottom: .25rem;
  font-family: Poppins-Light;
  line-height: 1rem;
  color: var(--text-body)
}

.card__more {
  position: absolute;
  right: 0;
  bottom: 1.75rem;
  width: 2rem;
  height: 2rem;
  padding: 0
}

@media(min-width: 1200px) {
  .card__more {
      bottom:2.5rem;
      width: 2.5rem;
      height: 2.5rem
  }
}

.card__more svg {
  pointer-events: none
}

.card__more:active svg path {
  fill: var(--icon-dark)
}

.card .icon-heart {
  width: 1rem;
  height: 1.25rem
}

.card .icon-heart path {
  stroke: var(--icon-dark)
}

.card__metadata-info {
  position: absolute;
  bottom: .625rem;
  font-size: .625rem;
  line-height: 1.2;
  color: var(--text-caption);
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  visibility: visible;
  word-break: break-word;
  text-align: left
}

.card__metadata-separator {
  margin: 0 .375rem
}

@media(min-width: 1200px) {
  .card__metadata-separator {
      margin:0 .5rem
  }
}

.card.viewed .card__viewed {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

:root {
  --white: #fff;
  --white-dark: #f5f5f5;
  --black: #000;
  --light-grey: #f2f4f8;
  --mid-grey: #ddd;
  --dark-grey: #5b5f64;
  --neutral-grey: #959595;
  --natural-grey: #b9b9b9;
  --beige: #f9f6ed;
  --bg-default-gradient: linear-gradient(135deg,#deffc9,#a3f8ff);
  --bg-theme-gradient: #f2f4f8;
  --bg-theme-segment: #fff;
  --bg-theme-sublist: #fff;
  --bg-theme: #f2f4f8;
  --bg-theme-light: #f2f4f8;
  --bg-main: #f2ede6;
  --blue-main: #3f52ff;
  --table-theme: #5b5f64;
  --table-theme-light: #f2f4f8;
  --bg-footer-theme: #fff;
  --bg-light: #fff;
  --bg-placeholder: #5b5f64;
  --icon-dark: #5b5f64;
  --icon-theme: #000;
  --img-theme: unset;
  --img-segment-theme: unset;
  --border-dark: #000;
  --text-theme: #000;
  --text-segment-theme: #000;
  --text-titles: #232323;
  --text-body: #48484d;
  --text-caption: #5f7081;
  --text-input: #424242;
  --selector-theme: #000;
  --user-color: #fff;
  --banner-pink: #ff829f;
  --banner-green: #95e1cd;
  --banner-dark-green: #89dcc4;
  --banner-yellow: #ffe38d;
  --border-theme: #5b5f64;
  --color-text-title: #232323;
  --color-text-body: #48484d;
  --color-text-small: #5f7081;
  --color-state-valid: #168128;
  --color-state-error: #d0021b;
  --color-state-disabled: #c8cace;
  --color-state-active: #c8cace;
  --color-text-form-title: #172128;
  --color-text-form-required-message: #37434f;
  --color-text-form-placeholder: #5c5c5c;
  --color-horizontal-rule: #c8cacb;
  --light-green: #dcf3ee;
  --color-helper-error: #d0021b;
  --focus: #2c69f6;
  --seashell: #f1f1f1;
  --bg-warning-email-exists: #dff6f0;
  --color-text-headline: #202020;
  --color-text-highlight: #fff1c1;
  --separator-primary: #c9c3ba
}

.footer {
    position:absolute;
    bottom:0;
    width:100%;
 }

 .card__shadow {
 -webkit-filter: drop-shadow(0 0 3px rgba(0,0,0,.2));
  filter: drop-shadow(0 0 3px rgba(0,0,0,.2))
}
